// $primary: #a51616;
$primary: #d24a43; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.logo {
 max-width: 350px;
 width: 100%;
 @media (max-width:960px) {
  margin-top: 10px;
  max-width: 300px;
 }
 @media (max-width:767px) {
  margin-top: 10px;
  margin-left: 5px;
  max-width: 175px;
 }
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
    	font-family: Muli, sans-serif;
    	font-size: 16px;
    	margin-top: 16px;
		text-align: center;
		height: 95px;
		display: flex;
		align-items: center;

		@media (max-width: 990px) {
			font-size: 13px;
		}

		@media (max-width: 767px) {
			margin-top: 0px;
			padding: 8px;
			height: auto;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 30%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}
.modal-title {
	font-family: Muli, sans-serif;
	font-size: 24px;
	text-align: center;
}
.modal-dialog {
	max-width: 300px;
	text-align: left;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
		border-radius: 20px;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}
#banner {
    position: relative;
}

#banner article {
	width: 100%;
	background-size: cover;
    padding-bottom: 40%;
    @media (max-width: 560px) {
    	padding-bottom: 50%;
    }
}

/* Arch element added as a :before pseudo element on #banner. Yes, z-index: 100 is probably a bit overkill. I'm okay with that. */
#banner:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
}

/* Negative z-index on the slides insures the image will be below the arch */
#banner .slides {
    position: relative;
    z-index: -1;
}

/* Also z-index: 100, like the arch. Since this comes after the arch in the markup, it will stay on top. */
/*
#banner-captions {
    position: absolute;
    left: 30%;
    top: 40%;
    z-index: 100;
    width: 100%;
    height: 100%;
}
*/

.container-fluid {
	width: 100%;
	padding: 0;
	margin: 0;
}

.transbox {
   /* For IE8 and earlier */
}

#banner .slides article .caption  {
	margin: 30px;
	position: absolute;
	transform: translate(-40%, -50%);
	top: 50%;
	left: 45%;
	@media (max-width: 767px) {
		transform: translate(-50%, -65%)
	}
	@media (max-width: 500px) {
		transform: translate(-60%, -75%)
	}
	h1 {
	@media all and (max-width: 960px) {
        font-size: 40px;
    }
    @media all and (max-width: 560px) {
        font-size: 30px;
    }
    @media all and (max-width: 360px) {
        font-size: 25px;
    }
    text-shadow: 1px 2px 3px black;
	font-family: 'Molle', cursive;
	color: red;
	font-size: 80px;
	}
	h2 {
	@media all and (max-width: 960px) {
        font-size: 20px;
    }
    @media all and (max-width: 560px) {
        font-size: 15px;
    }
    @media all and (max-width: 360px) {
        display: none;
    }
	text-shadow: 1px 2px 5px black;
	margin-top: -15px;
	font-family: 'Muli', sans-serif;
	color: white;
	font-size: 40px;
	}
}

p {
	font-size: 18px;
}

.col-xs-4.iconContainer {
	.iconHeadlines {
	margin-bottom: -5px;
	text-align: center;
	font-family: 'Muli', sans-serif;
	font-size:25px;
}
}

@media (max-width: 450px) {
	.col-xs-4.iconContainer {
		width: 100%;
	.iconHeadlines {
	margin-bottom: -5px;
	text-align: center;
	font-family: 'Muli', sans-serif;
	font-size:30px;
}
	}
}

.icon1 {
	display: block;
	margin: 0 auto;
	margin-top: 10px;
	max-width: 150px;
	width: 100%;
}
.icon2 {
	display: block;
	margin: 0 auto;
	margin-top: 10px;
	max-width: 150px;
	width: 100%;
}
.icon3 {
	display: block;
	margin: 0 auto;
	margin-top: 10px;
	max-width: 150px;
	width: 100%;
}
.bg {
	padding: 30px 0;
 	background-image: url('../img/iconbg.jpg');
	h1 {
		font-family: 'Patua One', cursive;
		color: white;
		font-size: 40px;
	}
}

a.btn.btn-large {
    background: #bb331a;
    color: white;
    padding: 20px 30px;
    max-width: 250px;
    font-size: 20px;
    font-family: 'Muli', sans-serif;
    display: block;
    margin: 20px auto;
}

.overlay {
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

/* HEADERS */

h5 {
	font-size:18px;
	font-family: Muli, sans-serif;
	color:white;
}

h4 {
	font-size:18px;
	font-family: Muli, sans-serif;
	color:black;
}

h6 {
	text-align: center;
	font-size:18px;
	font-family: Muli, sans-serif;
	color:black;
}

/* PARALLAX SECTION */

.parallaxbg {
	h1{
		text-shadow: 2px 2px red;
		font-family: 'Molle', cursive;
		font-size: 60px;
		text-align: center;
		color: white;
	}
	background: #b9523e;
    background: -webkit-linear-gradient(rgba(77, 89, 161, 0.8), rgba(77, 89, 161, 0.8)), url("../img/parallaxbg1.jpg") no-repeat;
    background: -o-linear-gradient(rgba(77, 89, 161, 0.8), rgba(77, 89, 161, 0.8)), url("../img/parallaxbg1.jpg") no-repeat;
    background: linear-gradient(rgba(77, 89, 161, 0.8), rgba(77, 89, 161, 0.8)), url("../img/parallaxbg1.jpg") no-repeat;
  	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0033FF', endColorstr='#4366c6',GradientType=0 );
	padding: 150px 0;
	background-attachment: fixed;
	background-size: cover;
	background-position: 10% 60%;
	@media (max-width:1024px) {
		background-attachment: scroll;
		background-position: 20% 50%;
		h1 {
		font-size: 40px;
	}
	}
	@media (max-width:767px){
		background-attachment: scroll;
		background-position: 40% 58%;
	}
}

.outrobg {
	padding: 150px 0px;
	background-image: url('../img/outrobg.jpg');
	background: #b9523e;
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("../img/outrobg.jpg") no-repeat;
    background: -o-linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("../img/outrobg.jpg") no-repeat;
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("../img/outrobg.jpg") no-repeat;
  	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0033FF', endColorstr='#4366c6',GradientType=0 );
	background-size: cover;
	background-position: 40% 30%;
	@media (max-width: 767px) {
	.headline {
		font-size: 40px;
	}
	background-position: 45% 50%;
	}
	@media (max-width: 460px) {
		.headline {
			font-size: 40px;
		}
	background-position: 40% 25%;
	}
}

.headline {
	margin-bottom: -5px;
	text-align: center;
	font-family: 'Molle', cursive;
	color: red;
	text-shadow: 1px 1px 0px blue;
	font-size:60px;
	@media (max-width: 767px) {
		font-size: 50px;
		margin-top: 0px;
	}
}

.headline3 {
	text-align: center;
	font-family: 'Molle', cursive;
	color: red;
	text-shadow: 1px 1px 0px blue;
	font-size:45px;
	@media (max-width: 767px) {
		font-size: 50px;
		margin-top: 0px;
	}
}